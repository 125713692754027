<template>
  <div class="midiun">
    <el-button @click="printData" style="width: 10cm;">{{sheetName}}</el-button>

<!--    ︵︶-->
    <div class="page" v-show="show">
      <template v-for="(item,index) in list">
        <div class="num" :key="index">
          {{item['牌位号']}}
        </div>
        <div class="item" :key="index + 'item'">
          <div class="item-address" :style="`font-size: ${resizeSide(item['地址'], 24)}px`" >
            <span v-show="item['地址']">地址</span>
            <div class="nbsp"></div>
            <span v-html="resizeNum(item['地址'])"></span>
          </div>

          <div class="item-center">
            <div class="item-center-line" :style="`font-size: ${resizeFont(item)}px;line-height: ${resizeFont(item)}px`" v-if="item['名称2']">{{item['名称2']}}</div>
            <div class="item-center-line" :style="`font-size: ${resizeFont(item)}px;line-height: ${resizeFont(item)}px`" v-if="item['名称1']">{{item['名称1']}}</div>
            <div class="item-center-line" :style="`font-size: ${resizeFont(item)}px;line-height: ${resizeFont(item)}px`" v-if="item['名称3']">{{item['名称3']}}</div>
          </div>

          <div class="item-people" v-if="item['阳上']" :style="`font-size: ${resizeSide(item['阳上'], 14)}px`">
            &nbsp;
            <span>阳上</span>
            <div class="nbsp"></div>
            <span v-html="replaceName(item['阳上'])"></span>

            <span></span>
            <span v-show="item['打印合家'] == 1">
              <div class="nbsp"></div>
              <span>合家</span>
            </span>
            <span v-show="item['打印合家'] == 2">
              <div class="nbsp"></div>
              <span>率合家孝眷</span>
            </span>
            <span v-show="item['打印合家'] == 3">
              <div class="nbsp"></div>
              <span>率合家眷属</span>
            </span>
            <div class="nbsp"></div>
            荐
          </div>

        </div>
      </template>

    </div>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    data: {
      type: [Object],
      default: () => ({})
    },
    sheetName: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      // sheetName: '超度中牌位',
      show: false
    };
  },
  computed: {
    header() {
      return Object.keys(this.data).length > 0 ? this.data['疏头'][0][this.sheetName] : ''
    },
    list() {
      let list = Object.keys(this.data).length > 0 ? this.data[this.sheetName] : []
      list = list.map(item => ({
        ...item,
        // 替换括号
        '地址': item['地址'] ? (item['地址'] + '')
                .replaceAll('（', '︵ ')
                .replaceAll('）', ' ︶')
            : '',
      }))
      return list
    }
  },
  watch: {
    show (val) {
      this.$emit('change', val)
    }
  },
  methods: {
    resizeFont(item) {
      let len = 0;
      for (let i = 0; i < 3; i++) {
        if (item[`名称${i}`] && item[`名称${i}`].length > 10){
          let itemLen = item[`名称${i}`].length - 10
          len = len < itemLen ? itemLen : len;
        }
      }
      if (len > 0) {
        let rs = 27 - len * 1.6;
        return rs < 12 ? 12 : rs;
      }
      if (len <= 0) {
        return 32
      }
    },
    resizeSide(str, min) {
      let len = 0
      if (str && str.length > 10){
        len = str.length - 10
      }
      let size = 28 - len * 1.6;
      if (min && size < min) {
        return min
      }
      return size
    },
    resizeNum(str) {
      return str.replace(/(\d+)/g, '<div style="font-size: 20px;">$1</div>')

    },
    printData() {
      this.show=true
      this.$nextTick(() => {
        window.print()
        this.show = false
      })
    },
    replaceName(str) {
      if (str) {
        return str
            // .replaceAll(' ', '<div style="height: .3cm"></div>')
            .replaceAll('（', '︵ ')
            .replaceAll('）', ' ︶')
            .replaceAll(':', ' : ')
            .replaceAll('：', ' : ')
            .replaceAll('-', ' |')
            .replaceAll('、', ' 、')
      }
      return ''
    }

  }
}
</script>

<style scoped>
.cm{
  width: .2cm;
}

.page {
  position: absolute;
  top: 0;
  background: #eee;
  width: 100%;
  font-size: 27px;
  line-height: 27px;
  font-family: '楷体';
  font-weight: bold;
}

.num {
  display: flex;
  margin-left: 8cm;
  padding-top: .7cm;
}

.item {
  width: 10cm;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 28cm;
  padding: 9.3cm 0.5cm 0 0.5cm;
  margin-bottom: 14cm;
}

.item-address{
  width: .5cm;
  margin-top: -3.3cm;
  margin-right: .4cm;
}

.item-center {
  width: 4.5cm;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
}

.item-center-line {
  width: .6cm;
  text-align: center;
}

.item-people {
  width: .5cm;
}

.nbsp {
  height: .4cm
}
</style>
