<template>
  <div class="wenshu">
    <el-button @click="printData" style="width: 10cm;">{{sheetName}}</el-button>

<!--    ︻︼︺︹-->
    <div class="page" v-show="show">
      <div class="title" :style="`font-size: ${resizeShuTou(header)}px`">
        {{header}}
        &nbsp;
        文疏
      </div>

      <div class="item" v-for="(item,index) in list" :key="index">
        <div class="item-text">
          <div>
            {{replaceName(item['名称'])}}
            <span v-show="item['打印合家'] == 1">
            <div class="nbsp"></div>
              <span>合家</span>
            </span>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    data: {
      type: [Object],
      default: () => ({})
    },
    sheetName: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    header() {
      return Object.keys(this.data).length > 0 ? this.data['疏头'][0][this.sheetName] : ''
    },
    list() {
      return Object.keys(this.data).length > 0 ? this.data[this.sheetName] : []
    },

  },
  watch: {
    show (val) {
      this.$emit('change', val)
    }
  },
  methods: {
    printData() {
      this.show=true
      this.$nextTick(() => {
        window.print()
        this.show = false
      })
    },
    resizeNum(str) {
      return str.replace(/(\d+)/g, '<div style="font-size: 20px;">$1</div>')
    },
    resizePaiWei(str) {
      return '︻' +  (str + '').replace(/(\d?)/g, '<div>$1</div>') + '︼'
    },
    resizeShuTou(str) {
      if (str.length < 16) {
        return 34
      }
      return 31
    },
    replaceName(str) {
      if (str) {
        return str
            // .replaceAll(' ', '<div style="height: .3cm"></div>')
            .replaceAll('（', '︵ ')
            .replaceAll('）', ' ︶')
            .replaceAll(':', ' :')
            .replaceAll('：', ' :')
            .replaceAll('-', ' |')
      }
    },
  }
}
</script>

<style scoped>
.cm{
  width: 1cm;
}

.page{
  position: absolute;
  top: 0;
  background: #eee;
  width: 27.7cm;
  display:flex;
  flex-direction: row-reverse;
  font-size: 24px;
  flex-wrap: wrap;
}

.title {
  height: 18.97cm;
  margin-left: 4.2cm;
  width: 1cm;
  /*line-height: 36px;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1cm;
  font-family: '隶书';
  font-size: 31px;
}

.title-label {
}

.item {
  height: 18.97cm;
  margin-left: .4cm;
}

.item-number {
  font-size: 20px;
  margin-bottom: .5cm;
  width: .3cm;
}

.item-text {
  height: 17.87cm;
  display: flex;
  flex-direction: row-reverse;
}

.item-text > div {
  width: 1cm;
  margin: 0 .1cm;
}

.nbsp {
  height: .4cm
}
</style>
