<template>
  <div class="wenshu">
    <el-button @click="printData" style="width: 10cm;">{{sheetName}}</el-button>

<!--    ︻︼︺︹-->
    <div class="page" v-show="show">
      <div class="title" :style="`font-size: ${resizeShuTou(header)}px`">
        {{header}}
        &nbsp;
        文疏
      </div>

      <div class="item" v-for="(item,index) in list" :key="index">
        <div class="item-text">
          <div class="item-number" v-show="item['牌位号']" v-html="resizePaiWei(item['牌位号'])"></div>

          <div v-if="item['地址']" class="item-address">
            <div>
              &nbsp;
              <span>地址</span>
              <div class="nbsp"></div>
              <span v-html="resizeNum(replaceName(item['地址'].substr(0, 18)))"></span>
            </div>
            <div v-if="item['地址'].length > 18">
              <div style="height: 2.8cm"></div>
              <span v-html="resizeNum(replaceName(item['地址'].substr(18, 20)))"></span>
            </div>
            <div v-if="item['地址'].length > 38">
              <div style="height: 2.8cm"></div>
              <span v-html="resizeNum(replaceName(item['地址'].substr(38, 20)))"></span>
            </div>
          </div>

          <div v-show="item['阳上']" class="item-up">
            &nbsp;
            <span>阳上</span>
            <div class="nbsp"></div>
            {{item['阳上']}}
            <span v-show="item['打印合家'] == 1">
            <div class="nbsp"></div>
              <span>合家</span>
            </span>
            <div class="nbsp"></div>
            <span>荐</span>
          </div>

          <div class="item-people">
            各姓门中历代宗亲父母师长
          </div>

          <div class="item-people">
            十方法界六道群灵
          </div>

          <div class="item-people">
            宿世今生冤亲债主
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    data: {
      type: [Object],
      default: () => ({})
    },
    sheetName: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    header() {
      return Object.keys(this.data).length > 0 ? this.data['疏头'][0][this.sheetName] : ''
    },
    list() {
      let list = Object.keys(this.data).length > 0 ? this.data[this.sheetName] : []
      list = list.map(item => ({
        ...item,
        // 替换括号
        '地址': item['地址'] ? (item['地址'] + '')
                .replaceAll('（', '︵ ')
                .replaceAll('）', ' ︶')
            : '',
      }))
      return list
    },

  },
  watch: {
    show (val) {
      this.$emit('change', val)
    }
  },
  methods: {
    printData() {
      this.show=true
      this.$nextTick(() => {
        window.print()
        this.show = false
      })
    },
    resizeNum(str) {
      return str.replace(/(\d+)/g, '<div style="font-size: 20px;">$1</div>')
    },
    resizePaiWei(str) {
      return '︻' +  (str + '').replace(/(\d?)/g, '<div>$1</div>') + '︼'
    },
    resizeShuTou(str) {
      if (str.length < 16) {
        return 34
      }
      return 31
    },
    replaceName(str) {
      if (str) {
        return str
            // .replaceAll(' ', '<div style="height: .3cm"></div>')
            .replaceAll('（', '︵ ')
            .replaceAll('）', ' ︶')
            .replaceAll(':', ' :')
            .replaceAll('：', ' :')
            .replaceAll('-', ' |')
      }
      return ''
    }
  }
}
</script>

<style scoped>
.cm{
  width: 1cm;
}

.page{
  position: absolute;
  top: 0;
  background: #eee;
  width: 27.7cm;
  display:flex;
  flex-direction: row-reverse;
  font-size: 26px;
  flex-wrap: wrap;
}

.title {
  height: 18.97cm;
  margin-left: 4.2cm;
  width: 1cm;
  /*line-height: 36px;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1cm;
  font-family: '隶书';
  font-size: 31px;
}

.title-label {
}

.item {
  height: 18.97cm;
  margin-left: 2.8cm;
}

.item-number {
  font-size: 20px;
  margin-bottom: .5cm;
  width: .3cm;
}

.item-text {
  height: 17.87cm;
  display: flex;
  flex-direction: row-reverse;
}

.item-text > div {
  margin: 0 .1cm;
}

.item-number, .item-up, .item-people {
  width: 1cm;
}

.nbsp {
  height: .4cm
}

.item-address{
  display: flex;
  flex-direction: row-reverse;
}

.item-address > div{
  width: 1cm;
}
</style>
