<template>
  <div class="midiun">
    <el-button @click="printData" style="width: 10cm;">{{sheetName}}</el-button>

    <!--   ︵︶  -->
    <div class="page" v-show="show">
      <div v-for="(item,index) in list" :key="index + 'item'">
        <div class="item">
          <div class="item-address" :style="`font-size: ${resizeSide(item['地址'], 12)}px`">
            <span v-html="resizeNum(replaceName(item['地址']))"></span>
          </div>

          <div class="item-people" :style="`font-size: ${resizeSoMach(item['阳上'])}px`" v-if="item['阳上']">
            <div class="item-people-line">
              <div>{{item['阳上'].substr(0, 10)}}</div>
              <div v-if="item['阳上'].length > 10">{{item['阳上'].substr(10,10)}}</div>
            </div>
            <div v-show="item['打印合家'] == 1" style="margin-left: -.2cm">
              <div class="nbsp"></div>
              <span>合家</span>
            </div>
          </div>
        </div>
        <div class="num">
          {{item['牌位号']}}
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    data: {
      type: [Object],
      default: () => ({})
    },
    sheetName: {
      type: [String],
      default: '普度小牌位'
    }
  },
  data() {
    return {
      // sheetName: '超度中牌位',
      show: false
    };
  },
  computed: {
    list() {
      let list = Object.keys(this.data).length > 0 ? this.data[this.sheetName] : []
      console.log(list)
      list = list.map(item => ({
        ...item,
        // 替换括号
        '地址': item['地址'] ? (item['地址'] + '').replaceAll('（', '︵ ').replaceAll('）', ' ︶') : '',
      }))
      return list
    }
  },
  watch: {
    show (val) {
      this.$emit('change', val)
    }
  },
  methods: {
    printData() {
      this.show=true
      this.$nextTick(() => {
        window.print()
        this.show = false
      })
    },
    resizeSide(str, min) {
      let len = 0
      if (str && str.length > 10){
        len = str.length - 10
      }
      let size = 20 - len * 1.6;
      if (min && size < min) {
        return min
      }
      return size
    },
    resizeNum(str) {
      return str.replace(/(\d+)/g, '<div style="font-size: 12px;">$1</div>')
    },
    resizeSoMach(str) {
      if (str.length <= 3) {
        return 24
      }
      if (str.length > 20) {
        return 12
      }
    },
    replaceName(str) {
      if (str) {
        return str
            // .replaceAll(' ', '<div style="height: .3cm"></div>')
            .replaceAll('（', '︵ ')
            .replaceAll('）', ' ︶')
            .replaceAll(':', ' :')
            .replaceAll('：', ' :')
            .replaceAll('-', ' |')
      }
      return ''
    }

  }
}
</script>

<style scoped>
div:nth-child(12n+ 10) {
  margin-bottom: 1.97cm;
}

.cm{
  width: 1cm;
}

.page {
  position: absolute;
  top: 0;
  background: #eee;
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-family: '楷体';
  flex-wrap: wrap;
  width: 29.6cm;
  padding: 1.1cm 1.9cm 2.2cm 2.1cm;
}

.item {
  width: 5.3cm;
  height: 12cm;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: 4.5cm;
  flex-shrink: 0;
}

.item-address{
  width: .4cm;
  padding-right: .6cm;
  font-size: 18px;
}

.item-people {
  width: .6cm;
  margin-top: 1.2cm;
  margin-left: .2cm;
}

.item-people-line {
  display: flex;
  flex-direction: row-reverse;
}

.item-people > div {
  width: .5cm;
}

.num {
  margin-left: -2.5cm;
  height: 1.32cm;
  flex-shrink: 0;
  display: flex;
  margin-left: 1cm;
}

.nbsp {
  height: .4cm
}
</style>
