<template>
  <div class="midiun">
    <el-button @click="printData" style="width: 10cm;">{{sheetName}}</el-button>

    <!--   ︵︶  -->
    <div class="page" v-show="show">
      <div v-for="(item,index) in list" :key="index + 'item'">
        <div class="item">
          <div class="item-address" :style="`font-size: ${resizeSide(item['地址'], 12)}px;`">
            <span v-html="resizeNum(replaceName(item['地址']))"></span>
          </div>

          <div class="item-center" v-if="item['名称'].length <= 30">
            <div class="item-center-line">{{replaceName(item['名称'].substr(0, 10))}}</div>
            <div class="item-center-line" v-if="item['名称'].length > 10">{{replaceName(item['名称'].substr(10, 10))}}</div>
            <div class="item-center-line" v-show="item['名称'].length > 20">{{replaceName(item['名称'].substr(20, 10))}}</div>
          </div>

          <div class="item-center" v-if="item['名称'].length > 30" style="font-size: 12px">
            <div class="item-center-line">{{item['名称'].substr(0, 15)}}</div>
            <div class="item-center-line" v-if="item['名称'].length > 15">{{replaceName(item['名称'].substr(15, 15))}}</div>
            <div class="item-center-line" v-show="item['名称'].length > 30">{{replaceName(item['名称'].substr(30, 15))}}</div>
            <div class="item-center-line" v-show="item['名称'].length > 45">{{replaceName(item['名称'].substr(45, 15))}}</div>
          </div>

          <div class="item-people" :style="`font-size: ${resizeSide(item['阳上'] + '合家')}px; line-height: ${resizeSide(item['阳上'] + '合家')}px;`">
            <span :style="`font-size: ${resizeSide(item['阳上'] + ' 合家', 12)}px; line-height: ${resizeSide(item['阳上'] + ' 合家', 12)}px`">
              <span v-html="replaceName(item['阳上'])"></span>
            </span>
            <span v-show="item['打印合家'] == 1" :style="`font-size: ${resizeSide(item['阳上'] + ' 合家', 12)}px; line-height: ${resizeSide(item['阳上'] + ' 合家', 12)}px`">
              <div class="nbsp"></div>
              <span>合家</span>
            </span>
          </div>
        </div>
        <div class="num">
          {{item['牌位号']}}
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    data: {
      type: [Object],
      default: () => ({})
    },
    sheetName: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      // sheetName: '超度中牌位',
      show: false
    };
  },
  computed: {
    list() {
      let list = Object.keys(this.data).length > 0 ? this.data[this.sheetName] : []
      list = list.map(item => ({
        ...item,
        // 替换括号
        '地址': item['地址'] ? (item['地址'] + '').replaceAll('（', '︵ ').replaceAll('）', ' ︶') : '',
      }))

      let rest = list.length % 5
      let newList = list.slice(0)
      for (let i = 0; i < (5 - rest); i++) {
        newList.push({
          '牌位号': '',
          '名称': '',
          '阳上': '',
          '地址': '',
          '打印合家': '',
        })
      }
      return newList
    }
  },
  watch: {
    show (val) {
      this.$emit('change', val)
    }
  },
  methods: {
    printData() {
      this.show=true
      this.$nextTick(() => {
        window.print()
        this.show = false
      })
    },
    resizeSide(str, min) {
      let len = 0
      if (str && str.length > 6){
        len = str.length - 6
      }
      let size = 18 - len * 1.6;
      if (min && size < min) {
        return min
      }
      return size
    },
    resizeNum(str) {
      return str.replace(/(\d+)/g, '<div style="font-size: 12px;">$1</div>')
    },
    resizeSoMach(str) {
      if (!str) {
        return 24
      }
      if (str.length <= 3) {
        return 24
      }
      if (str.legth > 20) {
        return 12
      }
    },
    replaceName(str) {
      if (str) {
        return str
            // .replaceAll(' ', '<div style="height: .3cm"></div>')
            .replaceAll('（', '︵ ')
            .replaceAll('）', ' ︶')
            .replaceAll(':', ' : ')
            .replaceAll('：', ' : ')
            .replaceAll('-', ' |')
            .replaceAll('、', ' 、')
      }
      return ''
    }
  }
}
</script>

<style scoped>
.cm{
  width: 1cm;
}

.page {
  position: absolute;
  top: 0;
  background: #eee;
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-family: '楷体';
  flex-wrap: wrap;
  width: 29.5cm;
  padding: 0cm 1.4cm 0cm 1.4cm;
}

.item {
  width: 4.6cm;
  height: 7cm;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /*padding-top: 3.5cm;*/
  flex-shrink: 0;
  padding: 0 .4cm;
  margin-top: 3.9cm;
}

.item-address{
  width: .4cm;
  padding-right: .6cm;
  font-size: 18px;
  margin-top: 2cm;
}

.item-people {
  width: .4cm;
  margin-top: 2.7cm;
}

.num {
  height: 3.2cm;
  flex-shrink: 0;
  display: flex;
  margin-left: .2cm;
  padding-top: 2.6cm;
  margin-bottom: 27.9cm;
}

.item-center {
  width: 2.4cm;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  font-size: 22px;
}

.item-center-line {
  width: 1cm;
}

.nbsp {
  height: .4cm
}
</style>
