<template>
  <div class="hello">
    <div class="upload">
      <el-upload ref="upload" action="" :auto-upload="false" :on-change="onChange" :on-remove="onRemove" :limit="1">
        <el-button type="primary" size="medium" style="width: 10cm">上传文件</el-button>
      </el-upload>
    </div>

    <div class="print-button" v-show="showBtn">
      <wen-shu :data="data" @change="show = $event" sheetName="普度文疏清单（超度）" />
      <br/>
      <wen-shu-xiao-zai :data="data" @change="show = $event" sheetName="普度文疏清单（消灾）"/>
      <br/>
      <wen-shu-chao-du :data="data" @change="show = $event" sheetName="超度文疏清单"/>
      <br/>
      <wen-shu-sui-xi :data="data" @change="show = $event" sheetName="随喜功德文疏清单"/>
      <br/>
      <pu-du-mini :data="data" @change="show = $event" sheetName="普度小牌位"/>
      <br/>
      <chao-du-mini :data="data" @change="show = $event" sheetName="超度小牌位"/>
      <br/>
      <pu-du-medium :data="data" @change="show = $event" sheetName="普度中牌位"/>
      <br/>
      <chao-du-medium :data="data" @change="show = $event" sheetName="超度中牌位"/>
      <br/>
      <pu-du-large :data="data" @change="show = $event" sheetName="普度大牌位"/>
      <br/>
      <chao-du-large :data="data" @change="show = $event" sheetName="超度大牌位"/>
      <br/>
    </div>
  </div>
</template>

<script>
import xlsx from 'xlsx'
import WenShu from "./WenShu"
import WenShuXiaoZai from "./WenShuXiaoZai"
import WenShuSuiXi from "./WenShuSuiXi"
import WenShuChaoDu from "./WenShuChaoDu"
import PuDuMedium from "./PuDuMedium"
import ChaoDuMedium from "./ChaoDuMedium"
import PuDuLarge from "./PuDuLarge"
import ChaoDuLarge from "./ChaoDuLarge"
import PuDuMini from "./PuDuMini"
import ChaoDuMini from "./ChaoDuMini"

export default {
  name: 'HelloWorld',
  components: {
    WenShu,
    WenShuXiaoZai,
    WenShuSuiXi,
    WenShuChaoDu,
    PuDuMedium,
    ChaoDuMedium,
    PuDuLarge,
    ChaoDuLarge,
    PuDuMini,
    ChaoDuMini,
  },
  props: {
    data: {}
  },
  data() {
    return {
      show: false,
      showBtn: false
    }
  },
  methods: {
    readFile: (file) => {
      return new Promise(resolve => {
        let reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onload = ev => {
          resolve(ev.target.result)
        }
      })
    },
    async onChange (file, fileList) {
      if (fileList.length == 0) {
        this.showBtn = false
        return
      }
      this.showBtn = true
      let dataBinary = await this.readFile(file.raw)
      let workBook = xlsx.read(dataBinary, {type: 'binary', cellDates: true})

      if (workBook.SheetNames.indexOf('疏头') === -1) {
        this.$message.error('表格中没有疏头！')
        this.$refs.upload.clearFiles()
        return false
      }

      let data = {}
      for (let sheetName of workBook.SheetNames) {
        let sheetData = workBook.Sheets[sheetName];
        data[sheetName] = xlsx.utils.sheet_to_json(sheetData)
      }
      this.data = data
    },
    onRemove(file, fileList) {
      if (fileList.length == 0) {
        this.showBtn = false
      }
    },
  }
}
</script>

<style scoped>
.hello {
  position: relative;
}

.upload {
  /*width: 10cm;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1cm 0;
}

.upload > div {
  width: 10cm;
}

.print-button {
  /*display: flex;*/
  /*justify-content: center;*/
}
</style>
